/* Global */

a {
  color: #00ffe9;
}

a:hover {
  color: #ff0076;
}

b {
  font-weight: 700;
}

p {
  font-size: 1.25rem;
  color: #C1D9F2;
}

hr {
  color: #C1D9F2;
}

h1 {
  font-weight: 700;
}

/* Header */

.site-header {
  background-color: #12173D;
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
}
.site-header a {
  color: #C1D9F2;
  transition: color .15s ease-in-out;
}
.site-header a:hover {
  color: #00ffe9;
  text-decoration: none;
}

.nav-link.active {
  color:#00ffe9!important;
  font-weight: 700;
  background-color: transparent !important;
  border-radius: 0;
}

.nav-link:hover,
.nav-link:focus {
  color:#00ffe9!important;
}

.navlogo {
  max-width: 7em;
}

.social {
  font-size: 1.5em;
  margin-right: -0.5em;
  color:#00ffe9!important;
}

/* Base */

body {
  text-shadow: 0 .05rem .1rem rgba(0, 0, 0, .5);
  font-family: 'Space Mono', monospace;
  background-color: #12173D;
}

.row {
  --bs-gutter-x: 0;
}

/* Buttons */

.dystopunks {
  color: #00ffe9;
}

.dekadente {
  color: #8CFF9B;
}

.dystolab {
  color: #ff0076;
}

.btn {
  border-radius: 0;
  text-shadow: 1px 1px 1px rgba(18, 23, 61, 0.5);
}

.btn.active,
.btn.focus {
  color: #FFFFFF !important;
  background-color: #ff0076;
  border-color: #ff0076;
  font-weight: 700;
 }

.btn-sm {
  border-bottom: 0 !important;
  padding: 3px 10px 3px 10px !important;
  margin: 3px;
}

.btn-icon {
  font-size: 1.3em;
  margin-bottom: -0.2em;
}

.btn-check:checked + .btn, .btn.group {
  color: #12173D !important;
  font-weight: 700;
  border-color: #8CFF9B;
  background-color: #8CFF9B;
 }

.btn-check + .btn:hover {
  color: #00ff84!important;
  background-color: rgba(0, 0, 0, 0.0);
  border-color: #8CFF9B;
}

.btn-primary {
  color: #12173D !important;
  background-color: #00ffe9;
  border-color: #00ffe9;
  border-bottom: .25rem solid#00ffe9!important;
  font-weight: 700;
}

.btn-primary:hover {
  color:#00ffe9!important;
  background-color: rgba(0, 0, 0, 0.0);
  border-color: #00ffe9;
}

.btn-primary:active,
.btn-primary:focus {
  color: #12173D !important;
  background-color:#00ffe9!important;
  border-color:#00ffe9!important;
}

.btn-secondary {
  color: #FFFFFF !important;
  background-color: #ff0076;
  border-color: #ff0076;
  border-bottom: .25rem solid#ff0076!important;
  font-weight: 700;
}

.btn-secondary:hover {
  color: #FFFFFF !important;
  background-color: rgba(0, 0, 0, 0.0);
  border-color: #ff0076;
}

.btn-secondary:active,
.btn-secondary:focus {
  color: #FFFFFF !important;
  background-color:#ff0076!important;
  border-color:#ff0076!important;
}

.btn-success {
  color: #12173D !important;
  background-color: #8CFF9B;
  border-color: #8CFF9B;
  border-bottom: .25rem solid #00ff84!important;
  font-weight: 700;
}

.btn-success:hover {
  color: #00ff84!important;
  background-color: rgba(0, 0, 0, 0.0);
  border-color: #8CFF9B;
}

.btn-success:active,
.btn-success:focus {
  color: #12173D !important;
  background-color: #00ff84!important;
  border-color: #00ff84!important;
}

.btn-light {
  color: #12173D !important;
  background-color: #909EDD;
  border-color: #909EDD;
  border-bottom: .25rem solid #909EDD !important;
  font-weight: 700;
}

.btn-light:hover {
  color: #909EDD !important;
  background-color: rgba(0, 0, 0, 0.0);
  border-color: #909EDD;
}

.btn-light:active,
.btn-light:focus {
  color: #12173D !important;
  background-color: #909EDD !important;
  border-color: #909EDD !important;
}

.btn-outline-primary {
  color:#00ffe9!important;
  border-color: #00ffe9;
}

.btn-outline-primary:hover {
  color:#ff0076!important;
  border-color: #ff0076;
  background-color: rgba(0, 0, 0, 0.0);
}

.btn-outline-primary:active,
.btn-outline-primary:focus {
  color: #12173D !important;
  border-color:#00ffe9!important;
  background-color:#00ffe9!important;
  font-weight: 700;
}

.btn-outline-secondary {
  color:#ff0076!important;
  border-color: #ff0076;
}

.btn-outline-secondary:hover {
  color:#00ffe9!important;
  border-color: #00ffe9;
  background-color: rgba(0, 0, 0, 0.0);
}

.btn-outline-secondary:checked {
  color:#00ffe9!important;
  border-color: #00ffe9;
  background-color: rgba(0, 0, 0, 0.0);
}

.btn-outline-secondary:active,
.btn-outline-secondary:focus {
  color: #FFFFFF !important;
  border-color:#ff0076!important;
  background-color:#ff0076!important;
  font-weight: 700;
}

.btn-outline-success {
  color: #00ff84!important;
  border-color: #8CFF9B;
}

.btn-outline-success:hover {
  color:#00ffe9!important;
  border-color: #00ffe9;
  background-color: rgba(0, 0, 0, 0.0);
}

.btn-outline-success:active,
.btn-outline-success:focus {
  color: #12173D !important;
  border-color: #00ff84!important;
  background-color: #00ff84!important;
  font-weight: 700;
}

.btn-claim-mobile {
  margin-top: 1em;
}

.btn-patch {
  border-radius: 0.3em;
}

.btn-loading {
  pointer-events: none;
}

button:disabled,
button[disabled]{
  background-color: #C1D9F2 !important;
  color: #464B8C !important;
  border-color: #C1D9F2 !important;
  border-bottom: .25rem solid #C1D9F2 !important;
  font-weight: 400;
}

/* Devices */

.product-device {
  position: absolute;
  right: 10%;
  bottom: -30%;
  width: 300px;
  height: 540px;
  background-color: #333;
  border-radius: 21px;
  transform: rotate(30deg);
}

.product-device::before {
  position: absolute;
  top: 10%;
  right: 10px;
  bottom: 10%;
  left: 10px;
  content: "";
  background-color: rgba(255, 255, 255, .1);
  border-radius: 5px;
}

.product-device-2 {
  top: -25%;
  right: auto;
  bottom: 0;
  left: 5%;
  background-color: #e5e5e5;
}

/* Utilities */

.flex-equal > * {
  flex: 1;
}
@media (min-width: 768px) {
  .flex-md-equal > * {
    flex: 1;
  }
}

/* Content */

.footer {
  color: #FFFFFF;
}

.link-secondary {
  color: #C1D9F2 !important;
}

.link-secondary:hover,
.link-secondary:focus,
.link-secondary:active {
  color:#00ffe9!important;
}

.text-muted {
  color: #C1D9F2 !important;
}

.leg1on {
  background: url('../public/img/backgrounds/leg1on-bg.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
  padding-top: 53em;
  padding-bottom: 10em;
  background-attachment: local;
}

.leg1on-sm {
  background: url('../public/img/backgrounds/leg1on-bg.png') no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
  padding-top: 43em;
  padding-bottom: 10em;
  background-attachment: local;
}

.wiz-comics {
  background: url('../public/img/backgrounds/wiz-bg.png') no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
  padding-top: 50em;
  padding-bottom: 10em;
  background-attachment: local;
}

.wiz-sm-comics {
  background: url('../public/img/backgrounds/wiz-sm-bg.png') no-repeat center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  box-shadow: inset 0 0 5rem rgba(0, 0, 0, .5);
  padding-top: 40em;
  padding-bottom: 10em;
  background-attachment: local;
}

.webb {
  margin-top: -10em;
  margin-left: -3em;
  max-width: 50em;
}

.wiz {
  max-width: 50em;
}

.liz {
  max-width: 40em;
}

.lead {
  color: #C1D9F2;
  text-align: left;
  line-height: 1.25em;
}

.weare {
  color: #12173D;
  background-color: #00ffe9;
}

.keys {
  color: #FFFFFF;
  background-color: #ff0076;
}

.trylogi {
  background-color: #293268;
}

.cards {
  background-color: #000000;
}

.card-item {
  transition: transform .2s;
  max-width: 95%;
}

.card-item:hover {
  transform: scale(1.05);
}

.comics {
  background: url('../public/img/backgrounds/comics-bg.png') no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: local;
  background-color: #ff0076;
}

.bg-text {
  border-radius: 1em;
  background-color: rgba(18, 23, 61, .5);
  padding-left: 2em;
  padding-right: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
}

.faq {
  background: url('../public/img/backgrounds/faq-bg.png') no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: local;
  background-color: #464B8C;
}

.devteam {
  border-radius: 0.3em;
  transition: transform .2s;
  max-width: 90%;
  margin-bottom: 0.1em;
}

.devteam:hover {
  border-radius: 0.3em;
  transform: scale(1.05);
}

.strong {
  font-weight: 700;
}

.sketch {
  padding: 0.5em;
}

.sketches {
  background: url('../public/img/backgrounds/sketches-bg.png') no-repeat fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-attachment: local;
  background-color: #464B8C;
}

.free-mint {
  color: #12173D;
  background-color: #8CFF9B;
  text-align: left;
  -webkit-clip-path: polygon(1.25rem 0%, 100% 0, 100% calc(100% - 1.25rem), calc(100% - 1.25rem) 100%, 0 100%, 0% 1.25rem);
  clip-path: polygon(1.25rem 0%, 100% 0, 100% calc(100% - 1.25rem), calc(100% - 1.25rem) 100%, 0 100%, 0% 1.25rem);
}

.mint {
  color: #12173D;
  background-color: #00ffe9;
  text-align: left;
  -webkit-clip-path: polygon(1.25rem 0%, 100% 0, 100% calc(100% - 1.25rem), calc(100% - 1.25rem) 100%, 0 100%, 0% 1.25rem);
  clip-path: polygon(1.25rem 0%, 100% 0, 100% calc(100% - 1.25rem), calc(100% - 1.25rem) 100%, 0 100%, 0% 1.25rem);
}

.mint-disabled {
  color: #12173D;
  background-color: #C1D9F2;
  text-align: left;
  -webkit-clip-path: polygon(1.25rem 0%, 100% 0, 100% calc(100% - 1.25rem), calc(100% - 1.25rem) 100%, 0 100%, 0% 1.25rem);
  clip-path: polygon(1.25rem 0%, 100% 0, 100% calc(100% - 1.25rem), calc(100% - 1.25rem) 100%, 0 100%, 0% 1.25rem);
}

.mint-text {
  color: #12173D;
}

.mint-tag {
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  color: #C1D9F2;
  background-color: #008782;
  border-radius: 0.3em;
  padding: 0.3em;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mint-tag-disabled {
  font-size: 0.5em;
  font-weight: 700;
  text-align: center;
  color: #C1D9F2;
  background-color: #909EDD;
  border-radius: 0.3em;
  padding: 0.3em;
  margin-left: 1em;
  margin-bottom: 1em;
}

.free-mint-tag {
  font-size: 1em;
  font-weight: 700;
  text-align: center;
  color: #C1D9F2;
  background-color: #14665B;
  border-radius: 0.3em;
  padding: 0.3em;
  margin-left: 1em;
  margin-bottom: 1em;
}

.mint-item {
  transition: transform .2s;
}

.mint-item:hover {
  transform: scale(1.05);
}

.collection {
  border-radius: 0.3em;
  padding: 0.1em;
  transition: transform .2s;
}

.form-check {
  color: #12173D;
  font-weight: 700;
  font-size: 0.5em;
  background-color: #C1D9F2;
  border-radius: 0 0 0.3em 0.3em;
  margin-top: -0.2em;
  margin-bottom: 0.3em;
  padding-top: 0.2em;
}

.input-group {
  padding: 0.2em;
}

.wallpapers {
  border: solid;
  border-color: #00ffe9;
}

.custom-cursor {
  cursor: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="40" height="48"><text x="0" y="20" font-size="28" fill="black">?</text></svg>'), auto;
}